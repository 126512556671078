import React from "react";
import Slider from "react-slick";
import useCheckMobileScreen from "../../lib/IsMobile";

const Carousel = ({ isVeritcal, slidesToShow, children }) => {
    const isMobile = useCheckMobileScreen();

    var settings = {
        dots: false,
        centerMode: isMobile ? false : true,
        verticalSwiping: isVeritcal ? false : true,
        infinite: true,
        arrows: false,
        autoplay: true,
        slidesToShow: isMobile ? 1 : slidesToShow,
        lazyLoad: true,
        // initialSlide: 1,
        variableWidth: true,
        speed: 2000,
        autoplaySpeed: 2000,
        cssEase: "linear",
    };
    return (
        <div className='autoplay-carousel'>
            {children && (
                <Slider {...settings}>
                    {children.map((child, index) => (
                        <div key={index}>{child}</div>
                    ))}
                </Slider>
            )}
        </div>
    );
};

export default Carousel;
